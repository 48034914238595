export const trackingDetails = () => {
  // const params = new URLSearchParams(window.location.search);
  try {
    var params = JSON.parse(sessionStorage.getItem("utm_params")) || {};
  } catch (e) {
    params = {};
  }

  return {
    pageUrl:
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname,
    pageName: window.document.title,
    ...params,
  };
};
