import React from 'react'

const LeadAPIFailedErrorMsg = () => {
  return (
    <div className="flex items-center text-center w-full h-full mt-4">
    <div className="message-wrapper w-full">
      <div className="flex justify-center">
        <img src={"/_a/icons/thumbs-up.svg"}
        alt='thimb-image'/>
      </div>
      <h3 className="error-title text-4xl my-8 text-center font-baskervville text-sky-900">Thank You For Your Interest.</h3>
      <p className="text-center text-sm text-gray-600 p-2 font-raleway font-medium">
      We have successfully received your information. One of our counselors will contact you shortly to help complete your registration.
      </p>
    </div>
  </div>
  )
}

export default LeadAPIFailedErrorMsg